import * as React from "react"
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import PageDetails from "../../../components/PageDetails";
import cadImg from "../../../images/canada-image-1.png"

let isLocal = ['dev', 'development'].includes(process.env.NODE_ENV);
let fr_url = (isLocal ? "" : process.env.FR_URL) + "/fr/a-propos/";
let en_url = (isLocal ? "" : process.env.EN_URL) + "/en/about/";

const About = () => {
    return (<>
            <Header title={"About us | Global Affairs Job Connector"}
                    description={"About us | Global Affairs Job Connector. Benefits of working for the Government of Canada abroad"}
                    lang={"EN"}
                    langLink={fr_url}
                    breadcrumbs={[{text: "Benefits of working for the Government of Canada abroad", url: en_url}]}
                    bodyClasses={"page-en-about"}/>
            <main property="mainContentOfPage" resource="#wb-main" class="container pb-5" typeof="WebPageElement">
                <h1>Benefits of working for the Government of Canada abroad</h1>
                <h2 id={"mandate"}>Our mandate</h2>
                <p>Global Affairs Canada collaborates with other government departments and other levels of government,
                    in a whole-of-Canada approach. Find <a href={"https://international.gc.ca"} rel={"noreferrer"} target={"_blank"}>our
                        mandate</a>.</p>
                <img src={cadImg} alt={"Canada"}/>
                <h2 id={"commitment"}>Our commitment</h2>
                <p>For the well-being of the local workforce abroad, the Government of Canada and its partners
                    provide:</p>
                <ul>
                    <li>good working conditions</li>
                    <li>an environment that promotes diversity and inclusion</li>
                    <li>the transfer of skills and competencies and life-long learning</li>
                </ul>
                <h2 id={"values"}>Our core values</h2>
                <p>All public servants*, must conduct themselves under the following values and expected behaviours:</p>
                <ul>
                    <li>Respect for democracy</li>
                    <li>Respect for people</li>
                    <li>Integrity</li>
                    <li>Stewardship</li>
                    <li>Excellence</li>
                </ul>
                <p>Public servants must show these values in Canada and abroad in:</p>
                <ul>
                    <li>their everyday work</li>
                    <li>their department</li>
                    <li>their dealings with:</li>
                    <ul>
                        <li>stakeholders (Other government departments, other governments, clients from the private
                            sector,
                            etc.)
                        </li>
                        <li>contractors</li>
                        <li>members of the public</li>
                    </ul>
                </ul>
                <p>These values and behaviours follow the Values and Ethics Code for the Public Sector. You can't
                    consider
                    them in isolation, as they often overlap. <b>*This includes Canada Based Staff and Locally Engaged
                        Staff.</b></p>
                <h2 id={"diversity"}>Diversity and inclusion</h2>
                <p>People and diversity matter. Our strength is the diversity of our people and the ideas they generate.
                    Our core values include treating all people with respect, dignity and fairness, with engagement and
                    inclusion being the building blocks of a healthy workplace</p>
                <p>Our organization offers an inclusive workplace. Respect, teamwork, and collaboration are part of our
                    culture. Canada’s missions abroad promote and support an environment free from harassment and
                    discrimination. They encourage and support employees to learn and develop their skills and
                    competencies.</p>
                <PageDetails lang={"EN"} dateModified={"2022-01-05"}/>
            </main>
            <Footer lang={"EN"}/>
        </>
    )
}
export default About;